import React from "react";
import i18n from '../../locale';
import Layout from "../../components/Layout";
import SEO from "../../components/seo";
import Title from '../../components/Title';
import Subtitle from '../../components/Subtitle';

export default ({ seo, location }) => (
  <Layout url='_url:404'>
    <SEO {...seo} location={location}/>
    <Title variant='h1'>
      {i18n('NOT FOUND')}
    </Title>
    <Subtitle>
      {i18n('This page does not exist.')}
    </Subtitle>
  </Layout>
);